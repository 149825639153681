@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.profile-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1DB954;
  padding: 10px;
}

.prepr-title-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prepr-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button {
  font-size: 24px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.profile-button-container {
  position: relative;
}

.profile-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.2s;
}

.profile-button:hover {
  background-color: #1ED760;
}

.profile-button img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.navigation-modal {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: none;
}

.navigation-modal.modal-open {
  display: block;
}

.navigation-modal button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 10px;
}

.navigation-modal button:hover {
  background-color: #1ED760;
}

.navigation-modal button:last-child {
  margin: 0;
}
.nav-menu {
    position: absolute;
    top: calc(100% + 5px);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: none;
}

.nav-menu.show {
    display: block;
}

.nav-menu button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-bottom: 10px;
}

.nav-menu button:hover {
    background-color: #1ED760;
}

.nav-menu button:last-child {
    margin-bottom: 0;
}
.profile-modal {
    position: absolute;
    top: calc(100% + 15px);
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: none;
  }
  
  .profile-modal.show {
    display: block;
  }
  
  .profile-modal button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-bottom: 10px;
  }
  
  .profile-modal button:hover {
    background-color: #1ED760;
  }
  
  .profile-modal button:last-child {
    margin-bottom: 0;
  }
  .prepr-logo-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .prepr-logo {
    width: 43px;
    height: auto;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .prepr-logo:hover {
    opacity: 0.8;
  }