.search-recipe {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recipe-results {
    display: flex;
    flex-direction: column;
    align-items: start;
}