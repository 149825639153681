.meal-plan {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.meal-plan-day {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 20px;
}

.meal-plan-meal {
    background-color: #ffffff;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
}

.meal-plan-meal img {
    width: 100%;
    height: auto;
    margin: 10px 0;
}

.meal-plan-meal h3 {
    margin-bottom: 20px;
    font-size: 18px;
}

.meal-plan-meal p {
    margin-top: 5px;
    color: #888888;
}

.meal-plan-meal button {
    margin: 0 5px;
    font-weight: 400;
    font-size: small;
    padding: 5px 10px;
    background-color: #1DB954;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 20px;
}

.meal-plan-meal button:hover {
    background-color: #1ED760;
}