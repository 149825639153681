* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    background-color: #f2f2f2;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .form-container {
    width: 600px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .input-field {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    width: 100%;
    padding: 15px;  /* Increased padding */
    background-color: #1DB954;
    color: white;
    font-size: 20px;  
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .forgot-password-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    text-align: right; 
    display: block;
  }
  
  .toggle-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding-left: 5px;
    text-decoration: underline;
  }
  
  .toggle-auth {
    margin-top: 20px;
  }
  
  .welcome-msg {
    font-size: 20px;
    color: #1DB954; 
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;  
  }
  