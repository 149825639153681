.app-container {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
}

.navbar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #0f1d2d;
    padding: 10px 0;
}

.nav-link {
    color: white;
    text-decoration: none;
    font-weight: 500;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1600px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}


.input-field {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button {
    width: 33%;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}


.toggle-auth {
    margin-top: 20px;
}

.toggle-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding-left: 5px;
    text-decoration: underline;
}

.app-container {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
}
.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1600px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}
.submit-button {
    width: 50%;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}
.welcome-msg {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #1DB954; 
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;  
}