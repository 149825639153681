.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.recipe-grid {
    display: flex;
    flex-direction: row;
    overflow-x: auto; 
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    overflow: scroll;
    white-space: nowrap;
}

.recipe-card {
    flex: 0 0 auto;
    width: 200px;
    display: flex; 
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    white-space: normal;
    position: relative; 
}

.submit-button {
    padding: 10px;
    background-color: #1DB954;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: auto; 
    width: 80%; 
}
.recipe-card h2 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #1DB954;
}

.recipe-card p {
    color: #888888;
}

.submit-button:hover {
    background-color: #1ED760;
}
