 .modal-container {
    background-color: #ffffff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 40%; 
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.edit-recipe-form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.edit-recipe-form label {
    font-weight: 500;
    margin-top: 10px;
}

.edit-recipe-form input,
.edit-recipe-form textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

.edit-recipe-form .dropzone {
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 4px;
    margin-top: 10px;
    text-align: center;
}

.edit-recipe-form ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.edit-recipe-form li {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 5px;
}

.edit-recipe-form img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edit-recipe-form button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.ingredient-row label {
    margin-right: 10px;
}

.add-ingredient-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.ingredient-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.remove-ingredient-button {
    margin-top: 31px;
    padding: 10px;
    background-color: #e01d0f;
    color: white;
    font-size: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.modal {
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    max-height: 80vh;
    overflow-y: auto; 
}
.edit-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.edit-form {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}

.input, .textarea {
    font-size: 14px;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.textarea {
    resize: vertical;
}

.ingredient-item, .photo-item {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.remove-button, .add-button, .submit-button {
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #1DB954;
    cursor: pointer;
    color: #ffffff;
}

.close-button {
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #e01d0f;
    cursor: pointer;
    color: #ffffff;
}
.remove-button:hover, .add-button:hover, .submit-button:hover {
    background-color: #52ab55;
}

.ingredients-title, .photos-title, .add-photos-title {
    margin-top: 20px;
    font-weight: bold;
}

.photos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.photo-item {
    margin-top: 10px;
}

.photo {
    max-width: 100px;
    height: auto;
}

.file-input {
    margin-top: 10px;
}