.form-container {
    background-color: #ffffff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 40%; 
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    justify-content: left;
    
}

.recipe-form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  
  .recipe-form label {
    font-weight: 500;
    margin-top: 10px;
  }
  
  .recipe-form input,
  .recipe-form textarea {
    box-sizing: border-box; 
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .recipe-form .dropzone {
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 4px;
    margin-top: 10px;
    text-align: center;
  }
  
  .recipe-form ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .recipe-form li {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 5px;
  }
  
  .recipe-form img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .recipe-form input[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #1DB954;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  .ingredient-row label {
    margin-right: 10px;
  }
  .add-ingredient-button {
    margin-top: 10px;
    padding: 10px;
    background-color: #1DB954;
    color: white; 
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .ingredient-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .remove-ingredient-button {
    margin-top: 31px;
    padding: 10px;
    background-color: #f44336; 
    color: white; 
    font-size: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }