body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 80%; 
  margin: 0 auto; 
}
.form-container h1 {
  margin-bottom: 10px;
  font-weight: 500;
  color: #1DB954;
}

.form-container h2 {
  margin-bottom: 30px;
  font-size: 18px;
  color: #888888;
}

.form-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.center-button {
  display: flex;
  justify-content: center;
}

.form-style input, .form-style select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: black;
}

.form-style button {
  padding: 5px 10px;
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form-style button:hover {
  background-color: #1DB954;
}

.signout-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.signout-button:hover {
  background-color: #1ED760;
}
.form-style select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: black;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="%231DB954" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px;
}

.form-style option {
  padding: 10px;
  background-color: #fafafa;
  color: black;
}
.height-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: black;
  width: 45%;
}
.height-input-container {
  display: flex;
  justify-content: space-between;
}


