.form-container {
    background-color: #ffffff;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 20%; 
    margin: 0 auto; 
}
.form-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .center-button {
    display: flex;
    justify-content: center;
  }
  
  .form-style input, .form-style select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: black;
  }
  
  .form-style button {
    padding: 5px 10px;
    background-color: #1DB954;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .form-style button:hover {
    background-color: #1ED760;
  }