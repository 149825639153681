.user-profile-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .user-profile-form label {
    font-weight: 500;
    margin: 10px 0;
    align-self: flex-start;
  }
  
  .user-profile-form input[type="text"],
  .user-profile-form input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .user-profile-form input[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #1DB954;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }